<template>
	<HeaderElement :textWhite="textWhite"/>
	<router-view />
	<OffCanvasMobileMenu />
</template>

<script>
	import HeaderElement from "./components/MyHeaderElement.vue";
	import OffCanvasMobileMenu from './components/MyOffCanvasMobileMenu.vue';
	import WOW from "wow.js";
	export default {
		components: {
			HeaderElement,
			OffCanvasMobileMenu,
		},
		name: 'app',
    data() {
      return {
        textWhite: true,
				showHeader: true,
      }
    },
    created(){
      // 監聽頁面狀態
      this.$router.beforeEach((to, from, next) => {
        // 監聽是否需要改變標頭文字顏色
        this.textWhite = !!to.meta.textWhite;
        // 繼續路由導航
        next();
      });
    },
		mounted(){
			new WOW().init();
		}
	}
</script>

