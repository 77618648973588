<template>
	<header class="header-area navbar-light fixed-top" :class="{ 'is-sticky': isSticky, 'text-white': textWhite }">
		<div class="container-fluid container-fluid--cp-150">
			<nav class="navbar navbar-expand-lg" id="navbar-example2">

				<!-- 品牌 Logo (至頂時)  -->
				<router-link to="/" class="navbar-brand logo-light">
					<img src="../assets/img/logo/logo-light.png" alt="logo">
				</router-link>

				<!-- 品牌 Logo (下拉時)  -->
				<router-link to="/" class="navbar-brand logo-dark">
					<img src="../assets/img/logo/logo-dark.png" alt="logo">
				</router-link>

				<!-- 頁面標籤 -->
				<div class="collapse navbar-collapse justify-content-center">
					<ul class="nav navbar-nav main-menu element-menu">
						<li v-for="(item, index) in menuData" :key="index" class="nav-item" :class="{ dropdown: item.children }" >
							<!-- 無子項 -->
							<div v-if="!item.children">
								<router-link :to="`/${item.path}`" class="nav-link">
									<span>{{ item.name }}</span>
								</router-link>
							</div>
							<!-- 有子項 -->
							<div v-else>
								<router-link to="" class="nav-link dropdown-toggle" id="elements" role="button" data-bs-toggle="dropdown" aria-expanded="false">
									<span>{{ item.name }}</span>
								</router-link>
								<ul class="dropdown-menu" aria-labelledby="elements">
									<li v-for=" (child, index) in item.children" :key="`${child.name}_${index}`">
										<router-link :to="`/${item.path}/${child.path}`" class="dropdown-item">{{ child.name }}</router-link>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>

				<!-- 小尺寸時的側邊選單 -->
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="mobiletoggleClass('addClass', 'active')">
					<span class="navbar-toggler-icon"></span>
				</button>

				<!-- 右側按鈕 -->
				<div class="btn-group header-button button--white d-none d-xl-block">
					<a :href="leleUrl" class="btn btn--white" style="font-weight: bold" target="_blank" rel="noopener noreferrer">管理中心</a>
				</div>
			</nav>
		</div>
	</header>
</template>

<script>
	import navMenuData from '../store/nav-menu-data';

	export default {
		props: [ 'textWhite', 'isShow' ],
		data (){
			return {
				isSticky: false,
				leleUrl: '',
				menuData: navMenuData,
			}
		},
		mounted () {
			this.leleUrl = process.env.VUE_APP_LELE_WEB_URL;
			window.addEventListener('scroll', () => {
				let scroll = window.scrollY
				if(scroll >= 200){
					this.isSticky = true
				} else {
					this.isSticky = false
				}
			})
		},

		methods: {
			// offcanvas searchbox
			toggleClass(addRemoveClass, className) {
				const el = document.querySelector('#search-overlay');
				if (addRemoveClass === 'addClass') {
					el.classList.add(className);
				} else {
					el.classList.remove(className);
				}
			},
			// offcanvas searchbox
			mobiletoggleClass(addRemoveClass, className) {
				const el = document.querySelector('#offcanvas-menu');
				if (addRemoveClass === 'addClass') {
					el.classList.add(className);
				} else {
					el.classList.remove(className);
				}
			},
		},
	}
</script>

<style lang="scss">
	.mobile-menu-bar {
		color:#6d70a6;
		font-size: 25px;
		border: 1px solid#6d70a6;
		padding: 8px 10px 10px;
		line-height: 1;
		height: 42px;
		margin-top: 6px;
		border-radius: 4px;
	}

	.element-menu {
		[aria-expanded="false"] {
			background-color: transparent;
		}
		.dropdown-item {
			font-size: 14px;
			&:hover {
				color: #5945e6;
				background-color: transparent;
			}
		}
		.dropdown-menu {
			border: none;
			border-bottom: 3px solid #5945e6;
			margin-top: -2px;
		}
	}
</style>