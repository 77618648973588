import { createRouter, createWebHistory } from 'vue-router';

const routes = [
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
	},
	{
		path: '/',
		alias: '/home',
		name: 'Home',
		meta: { textWhite: true },
		component: () => import(/* webpackChunkName: "Home" */ '../views/HomeView.vue'),
	},
	{
		path: '/services',
		name: 'Services',
		children: [
			{
				path: 'intercom',
				name: 'Intercom',
				component: () => import(/* webpackChunkName: "Intercom" */ '../views/IntercomView.vue'),
			},
			{
				path: 'app',
				name: 'App',
				meta: { textWhite: true },
				component: () => import(/* webpackChunkName: "AppService" */ '../views/AppView.vue'),
			},
			{
				path: 'web',
				name: 'Web',
				meta: { textWhite: true },
				component: () => import(/* webpackChunkName: "Web" */ '../views/WebView.vue'),
			},
		],
	},
	{
		path: '/support',
		name: 'Support',
		children: [
			{
				path: 'faq',
				name: 'Faq',
				component: () => import(/* webpackChunkName: "Faq" */ '../views/FaqView.vue'),
			},
			{
				path: 'resource',
				name: 'Resource',
				component: () => import(/* webpackChunkName: "Resource" */ '../views/ResourceView.vue'),
			},
			{
				path: 'privacy',
				name: 'Privacy',
				component: () => import(/* webpackChunkName: "Privacy" */ '../views/PrivacyView.vue'),
			},
			{
				path: 'account_delete',
				name: 'AccountDelete',
				component: () => import(/* webpackChunkName: "AccountDelete" */ '../views/AccountDeleteView.vue'),
			},
		],
	},
	{
		path: '/aboutus',
		name: 'Aboutus',
		meta: { textWhite: true },
		component: () => import(/* webpackChunkName: "Aboutus" */ '../views/AboutusView.vue'),
	},
	{
		path: '/branding',
		name: 'Branding',
		component: () => import(/* webpackChunkName: "Branding" */ '../views/HomeBranding.vue'),
	},
	{
		path: '/privacy-policy',
		name: 'PrivacyPolicy',
		component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/pages/PrivacyPolicy.vue'),
	},
];
  
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});
  
export default router;
