// 標題標籤頁索引配置數據
export default [
  { name: "Home Pages", path: "home" },
  { name: "產品&服務", path: "services", children: [
    { name: "智慧對講機", path: "intercom" },
    { name: "樂樂APP", path: "app" },
    { name: "樂樂管理後台", path: "web" },
  ]},
  { name: "售後&資源", path: "support", children: [
    { name: "常見問題", path: "faq" },
    { name: "社區資源", path: "resource" },
    { name: "隱私權政策", path: "privacy" },
    { name: "帳號刪除說明", path: "account_delete" },
  ]},
  { name: "關於我們", path: "aboutus" },
];